import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Drohnen from '../components/Drohnen'
import Downloads from '../components/Downloads'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import Ausleihe from '../components/Ausleihe'
import Dokumente from '../components/Dokumente'
import Spacer from '../components/Spacer'


export const ServicesPageTemplate = ({
  drohnen,
  ausleihe,
  downloads,
}) => (
    <div>
      <Helmet title="Services" defer={false}/>
      <Layout activePageIndex={4}>
        <Spacer height={75}/>
        <Drohnen data={drohnen}/>
        <Spacer height={150}/>
        <Ausleihe data={ausleihe}/>
        <Spacer height={150} heightS={125}/>
        <Dokumente data={downloads}/>
        <Spacer height={75}/>
      </Layout>
    </div>
)

ServicesPageTemplate.propTypes = {
  drohnen: PropTypes.object,
  ausleihe: PropTypes.object,
  downloads: PropTypes.object,
}