import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DownloadElementShrink from './DownloadElementShrink';

const Container = styled.div`
position:relative;
width:90%;
max-width:1400px;
margin:0 auto;
font-size:16px;

& > h2 {
    font-family:Montserrat;
    font-style:italic;
    font-size:20px;
    text-align:center;
}

@media(min-width:601px){
    & > h2{
        font-size:24px;
    }
}
`

const ListContainer = styled.div`
display:block;
width:90%;
max-width:275px;
margin:0 auto;

@media(min-width:600px){
    max-width:none;
    width:560px;
}

@media(min-width:993px){
    display:block;
    width:845px;
}

@media(min-width:1200px){
    width:1130px;
}
`

const List = styled.div`
display:flex;
flex-flow:row wrap;
gap:10px;
`

const Dokumente = ({data}) => (
    <Container id={data.id}>
        <h2>{data.header}</h2>
        <ListContainer>
            <List>
                {
                    data.dokumentListe.map((item,i) => (
                        <DownloadElementShrink key={i} link={item.link}>{item.name}</DownloadElementShrink>
                    ))
                }
            </List>
        </ListContainer>
    </Container>
);

Dokumente.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.string,
        header: PropTypes.string,
        dokumentListe: PropTypes.arrayOf(PropTypes.shape({
            link: PropTypes.string,
            name: PropTypes.string,
        }))
    })
};

export default Dokumente;