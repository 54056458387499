import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown'
import PreviewCompatibleImage from './PreviewCompatibleImage';

const Container = styled.div`
position:relative;
display:flex;
flex-direction:column;
align-items:center;
width:90%;
max-width:1400px;
margin:0 auto;

& > h2 {
    font-family:Montserrat;
    font-style:italic;
    font-size:24px;
    text-align:center;
}

& > p {
    max-width:600px;
    text-align:justify;
    margin:0 auto;
    font-family:Lato;
    font-size:16px;
}

@media(min-width:601px){
    & > h2{
        font-size:28px;
    }
}
`
const Item = styled.div`
position:relative;
height:295px;
width:100%;
max-width:270px;


& p {
    margin:5px;
    font-family:Lato;
    font-weight:400;
    font-size:16px;
    color:var(--tblack);
    text-align:center;
}
`
const Image = styled.div`
position:relative;
width:100%;
height:100%;
max-width:270px;
max-height:250px;


& > * {
    width:100%;
    height:100%;
}


`

const ListContainer = styled.div`
max-width:275px;
margin:50px auto;

@media(max-width:600px){
    & > div {
        & > *:nth-child(n+4){
            display:${props => props.expanded==1?('block'):('none')};
        }
    }
}

@media(min-width:601px){
    max-width:none;
    width:560px;
}

@media(min-width:993px){
    display:block;
    width:845px;
}

@media(min-width:1200px){
    width:1130px;
}
`

const List = styled.div`
display:flex;
flex-flow:row wrap;
gap:15px;
`

const RedButton = styled.button`
display:${props => props.expanded==1?('none'):('inline-block')};
background-color:var(--tmain);
border: 2px solid var(--tmain);
border-radius:8px;
color:var(--twhite);
padding:0px 10px 2px 10px;
text-decoration:none;
transition: 0.2 ease;
text-align:center;
margin:0;

font-family:Montserrat;
font-weight:400;
font-style:italic;
font-size:16px;

&::before {
    content:"»";
    color:var(--twhite);
    font-size:22px;
    margin-right:8px;
}

&:hover{
    color:var(--tmain);
    background-color:var(--twhite);
    cursor:pointer;

    &::before {
        color:var(--tmain);
    }
}

@media(min-width:601px){
    display:none;
}
`

const Ausleihe = ({data}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpansion = () => (
        setIsExpanded(!isExpanded)
    );

    return(
        <Container id={data.id}>
            <h2>{data.header}</h2>
            <ReactMarkdown>{data.text}</ReactMarkdown>
            <ListContainer expanded={isExpanded}>
                <List>
                    {
                        data.gegenstandsListe.map((item,i) => (
                            <Item key={i}>
                                <Image>
                                    <PreviewCompatibleImage imageInfo={item.image} objectFit="contain"/>
                                </Image>
                                    <p>{item.text}</p>
                            </Item>
                        ))
                    }
                </List>
            </ListContainer>
            <RedButton onClick={toggleExpansion} expanded={isExpanded}>alle anzeigen</RedButton>
        </Container>
    )
}

Ausleihe.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    header: PropTypes.string,
    text: PropTypes.string,
    gegenstandsListe: PropTypes.arrayOf( PropTypes.shape({
      image: PropTypes.oneOfType( [PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })),
  }),
}


export default Ausleihe