import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PdfIcon from '../icons/PDF_Icon.svg'
import DocxIcon from '../icons/DOCX_Icon.svg'
import FileIcon from '../icons/FILE_Icon.svg'
import RedButton from './RedButton';

const { stripStatic } = require("../../myutil")


const Container = styled.div`
display:flex;
flex-direction:column;
justify-content:space-between;
margin:20px 0;
width:275px;

& > div {
    display:flex;
    flex-direction:row;
    align-items:center;

    & > p {
        font-family:Montserrat;
        font-weight:700;
        font-style:italic;
        margin-left:15px;
        max-width:210px;
    }
    align-self:flex-start;
}

& a {
    margin-top:15px;
    align-self:center;
}

& svg {
    width:65px;
    height:65px;
}
`

const DownloadElementShrink = ({children,link}) => (
    <Container>
        <div>
        {
            link.includes(".pdf") && <PdfIcon/>
        }
        {
            link.includes(".docx") && <DocxIcon/>
        }
        {
            (!link.includes(".docx") && !link.includes(".pdf")) && <FileIcon/>
        }
        <p>{children}</p>
        </div>
        <RedButton href={stripStatic(link)}>download</RedButton>
    </Container>
);

DownloadElementShrink.propTypes = {
    link: PropTypes.string,
};

export default DownloadElementShrink;