import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
//may delete these 2 if not used
import PreviewCompatibleImage from './PreviewCompatibleImage'
import ReactMarkdown from 'react-markdown'


const Container = styled.div`
position:relative;
width:100%;
padding:5px;
max-width:1400px;
margin:0 auto;
text-align:center;
& > h2 {
  margin-bottom:50px;
  font-family:Montserrat;
  font-weight:700;
  font-style:italic;
  font-size:24px;
  text-align:center;
}

@media(min-width:601px){
  & > h2{
    font-size:28px;
  }
}
` 
const Image = styled.div `
margin:auto;
position:relative;
max-width:1080px;
width:100%;
height:auto;

& >* {
    height:100%;
    width:100%;
}
`       

const Drohnen = ({data}) => (
  <Container id={data.id}>
    <h2>{ data.header }</h2>
    <Image>
      <PreviewCompatibleImage imageInfo = { data } objectFit="contain"/>
    </Image>
    
  </Container>
)

Drohnen.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    header: PropTypes.string,
    image: PropTypes.oneOfType( [PropTypes.object, PropTypes.string]),
  }),
}


export default Drohnen