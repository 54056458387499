import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { ServicesPageTemplate } from '../templates/services-template'

const ServicesPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <ServicesPageTemplate
      drohnen = { frontmatter.drohnen }
      ausleihe = { frontmatter.ausleihe }
      downloads = { frontmatter.downloads }
    />
  )
}

ServicesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  })
}

export default ServicesPage

export const servicesPageQuery = graphql`
query ServicesTemplate {
  markdownRemark(frontmatter: { templateKey: { eq: "services-page"}}) {
    frontmatter {
      
      drohnen {
        id        
        header        
        image
          {
          childImageSharp {
            gatsbyImageData(
              width: 800,
            )}
          }
        
      }
      ausleihe {
        id        
        header        
        text        
        gegenstandsListe {
          image
            {
            childImageSharp {
              gatsbyImageData(
                width: 800,
              )}
            }
          
          text          
        }
      }
      downloads {
        id        
        header        
        dokumentListe {
          link          
          name          
        }
      }
    }
  }
}
`