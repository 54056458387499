import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.h1`
text-align:center;
font-family:Montserrat;
font-size:28px;
margin-top:25px;

@media(min-width:993px){
  font-size:36px;
  margin-top:50px;
}
`

const PageHeader = ({children}) => (
    <Container>{children}</Container>
);

PageHeader.propTypes = {

};

export default PageHeader;