import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
//may delete these 2 if not used
import PreviewCompatibleImage from './PreviewCompatibleImage'
import ReactMarkdown from 'react-markdown'
import PDF_Icon from '../icons/PDF_Icon.svg'
import DOCX_Icon from '../icons/DOCX_Icon.svg'
import RedButton from './RedButton'
import DownloadElementShrink from './DownloadElementShrink'

const Container = styled.div`
position:relative;
padding:4%;
width:100%;
max-width:1400px;
margin:auto;
& > h2 {
  font-family:Montserrat;
  font-weight:700;
  font-style:italic;
  font-size:24px;
  text-align:center;
}

@media(min-width:601px){
  & > h2{
    font-size:28px;
  }
}
`     
const DownloadsList = styled.div`
margin-top:50px;
display:flex;
flex-flow: row wrap;
justify-content:space-evenly;
gap:10px;

`   
const Downloadable = styled.div`
position:relative;
max-width:320px;
width:100%;
display:flex;
align-items:center;
& svg {
  height:60px;
  width:60px;
  margin-right:30px;
}
& > div {
  display:flex;
  width:100%;
  flex-direction: column;
  & > p {
    font-family:Montserrat;
    font-weight:700;
    font-style:italic;
    font-size:16px;
  }
  & > a {
    align-self:end;
  }
}

@media(min-width:601px){
  & svg {
    height:75px;
    width:75px;
    margin-right:30px;
  }

  & > div {
    & > p {
      font-size:18px;
    }
  }
}
`
const Downloads = ({data}) => (
  <Container id={data.id}>
    <h2>{data.header}</h2>
    <DownloadsList>
      {
        data.downloadItems.map((item,i) => (
          <DownloadElementShrink link={item.link}>{item.name}</DownloadElementShrink>
        ))
      }
    </DownloadsList>
  </Container>
)

Downloads.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    header: PropTypes.string,
    dokumentListe: PropTypes.arrayOf( PropTypes.shape({
      link: PropTypes.object,
      name: PropTypes.string,
    })),
  }),
}


export default Downloads